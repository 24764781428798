@import '../../../../styles/constants.scss';

.wheelInput {
    display: inline-block;
    $optionHeight: 32px;
    .scrollableZone {
        // scroll is handled by javascript
        overflow: hidden;
        touch-action: none;
        height: $optionHeight * 3;
        position: relative;
        .options {
            position: relative;
            padding: $optionHeight .5rem;
            transition: top 100ms ease-out;
            .option {
                display: flex;
                align-items: center;
                height: $optionHeight;
                &.selected {
                    color: $main-color;
                }
            }
        }
        .mask {
            position: absolute;
            z-index: 1;
            top: 0;
            width: 100%;
            height: $optionHeight * 3;
            background: rgba(255,0,0,.2);
            $treshold1: 15%;
            $treshold2: 40%;
            $interAlpha: .55;
            background:
                linear-gradient(
                    to bottom,
                    #fff,
                    rgba(#fff,$interAlpha) $treshold1,
                    rgba(#fff,0%) $treshold2,
                    rgba(#fff,0%) #{100% - $treshold2},
                    rgba(#fff,$interAlpha) #{100% - $treshold1},
                    #fff
                );
        }
    }

    &.touching .scrollableZone .options  {
        transition: none;
    }
}

.root {
    width: auto;
    margin: 0 auto;
    .box {
        display: inline-block;
        box-shadow: 0 0 2px rgba(#000,.3);
        border-radius: 3px;
        min-width: 180px;
        .permaDisplay {
            display: flex;
            flex-wrap: nowrap;
            padding: $spacing;
            span {
                flex: 1;
            }
            .icon {
                flex: 0 0 1rem;
                color: $main-color;
                visibility: hidden;
            }
        }
        .dropdown {
            overflow: hidden;
            max-height: 0;
            transition: max-height 150ms;
            .colorText {
                color: $main-color;
            }
            .pickTool {
                display: flex;
                align-items: center;
                border-width: 1px 0;
                border-style: solid;
                border-color: $light-grey;
                padding: $spacing;
            }
            .submitBtn {
                margin: $spacing;
            }
        }
    }
    &.open .permaDisplay .icon {
        visibility: visible;
    }
}
