@import '../../../../styles/constants.scss';
@import '../../../../styles/mixins.scss';

.textField {
    @include inputDefaults();

    input {
        font-size: 1rem;
        border: 1px solid $grey;
        border-radius: 3px;
        background: none;
        outline: none;
        padding: $spacing/2 $spacing;
        width: 100%;
    }
}