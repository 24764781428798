.root {
  border-bottom: 1px solid #e3e5e6;
  position: relative;
}

.logoImg {
  height: 37px;
}

.notifZone {
  position: absolute;
  right: 0;
  top: 0;
  padding-right: 4px;
  padding-left: 20px;
  overflow-x: hidden;
}
