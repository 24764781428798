.textarea {
    width: 100%;
    resize: none;
    border: 1px solid grey;
    border-radius: 3px;
}

.submitRow {
    text-align: center;
}

.btnRow {
    text-align: center;
    margin-bottom: 20px;
}