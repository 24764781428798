@import '../../../../styles/constants.scss';
.labelsRow {
    margin-bottom: 2*$spacing;
    position: relative;
    // 1.5rem is default line-height
    height: 1.5rem;
    .label {
        position: absolute;
        top: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: center;
        transform: translateX(-50%);
        &:first-child {
            transform: translateX(0);
        }
        &:last-child {
            transform: translateX(-100%);
        }
    }
}