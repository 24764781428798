@import '../../styles/constants.scss';


.label {
    text-transform: uppercase;
    color: $grey;
    font-size: .7rem;
    letter-spacing: .025rem;
    margin-bottom: $spacing;
    font-weight: bold;
    &.light {
        font-weight: normal;
        color: inherit;    
    }
}