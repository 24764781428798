.root {
    display: flex;
    padding: 5vmin;
}

.infos {
    flex: 0 0 400px;
}

.dummy {
    flex: 1;
    height: 80vmin;
    padding: 0 10%;
}