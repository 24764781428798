.container {
    flex: 1;
    position: relative;
}

.dummy {
    position: absolute !important;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}