@import '../styles/constants.scss';

.root {
    display: flex;
    flex: 1;
}

.content {
    flex-grow: 1;
    padding: $spacing * 3;
    height: 100%;
    overflow: auto;
}