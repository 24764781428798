@import '../../styles/constants.scss';

.main {
    width: auto;
    display: block; // Fix IE 11 issue.
    margin-left: $spacing * 3;
    margin-right: $spacing * 3;
    margin-top: $spacing;

    header {
        font-size: 1.5rem;
        font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    }

    // for screen at least 400px (+margins) wide:
    @media screen and (min-width:400px + $spacing * 3 * 2) {
        max-width: 400px;
        margin-top: $spacing * 20;
        margin-left: auto;
        margin-right: auto;
    }
}
.form {
    width: 100%; // Fix IE 11 issue.
    margin-top: $spacing;
    display: flex;
    flex-direction: column;
    
    .submitError {
        margin-top: $spacing;
        color: $strawberry;
        text-align: center;
    }
}