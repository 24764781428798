@import './constants.scss';

@mixin inputDefaults {
    margin: 0 $spacing;
    display: inline-block;
    vertical-align: bottom;
    &.fullWidth {
        width: calc(100% - #{2*$spacing});
    }
    &.disabled {
        opacity: .7;
        &, * {
            cursor: not-allowed;
        }
    }

    label {
        width: 100%;
        margin: 0;
        &>* {
            display: block;
        }
    }
}