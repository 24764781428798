@import '../../styles/constants.scss';
@import '../../styles/mixins.scss';

$transition-duration: 150ms;

.textArea {
    @include inputDefaults();

    textarea {
        width: 100%;
        resize: vertical;
        font-size: 1rem;
        border: 1px solid $grey;
        border-radius: 3px;
        background: none;
        outline: none;
        padding: $spacing/2 $spacing;
    }
}

.dropDown {
    @include inputDefaults();
    text-align: left;

    .searchField {
        margin: $spacing;
    }

    .dropDownWrapper {
        position: relative;
        .box {
            display: flex;
            width: 100%;
            resize: vertical;
            font-size: 1rem;
            border: 1px solid $grey;
            border-radius: 3px;
            background: none;
            outline: none;
            padding: $spacing/2 $spacing;
            padding-right: 0;
            // mimic native inputs default size
            min-width: 192px;
            cursor: pointer;
            .valueDisplay {
                flex: 1 1 0;
            }
            .icon {
                flex: 0 0 auto;
                transform: rotate(0deg);
                transition: transform $transition-duration ease-in;
            }
        }
        $transition-time: 150ms;
        .dropPanel {
            position: absolute;
            z-index: 100;
            top: 100%;
            left: 0;
            width: 100%;
            background: #FFF;
            box-shadow: 0 1px 3px rgba(0,0,0,.3);
            max-height: 0;
            overflow: auto;
            transition-property: transform, opacity, max-height;
            transition-duration: $transition-duration;
            transition-timing-function: ease-in;
            transform-origin: 50% 0;
            opacity: 0.4;
            transform: scale(.8);
            .option {
                cursor: pointer;
                padding: $spacing/2 $spacing;
                white-space: nowrap;
                max-width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
                &.selected {
                    color: $main-color;
                }
                &:hover {
                    background: $light-grey;
                }
            }
        }
    }
    &.open .dropDownWrapper {
        .icon {
            transform: rotate(180deg);
            transition-timing-function: ease-out;
        }
        .dropPanel {
            transform: scale(1);
            opacity: 1;
            max-height: 200px;
            transition-timing-function: ease-out;
        }
    }
}

.checkbox {
    $handle-size: 20px;
    $inner-padding: 1px;
    @include inputDefaults();
    margin-top: $spacing;
    margin-bottom: $spacing;
    input{
        display: none;
    }
    label {
        display: flex;
        align-items: center;
        &>* {
            margin: 0;
        }
    }
    .display {
        box-sizing: content-box;
        width: $handle-size;
        height: $handle-size;
        color: #FFF;
        align-items: center;
        font-size: 0;
        justify-content: center;
        display: flex;
        background-color: #FFF;
        border: 1px solid $grey;
        border-radius: 5px;
        padding: $inner-padding;
        margin-right: $spacing/2;
        position: relative;
        transition-property: background-color, border-color;
        transition-duration: $transition-duration;
        transition-timing-function: ease-in;
        .icon {
            transition: all $transition-duration ease-in;
            width: 0;
            height: 0;
        }
    }
    &:not(.disabled) .display {
        cursor: pointer;
    }
    input:checked + .display {
        transition-timing-function: ease-out;
        background-color: $main-color;
        border-color: $main-color;
        font-size: 1em;
        .icon {
            transition-timing-function: ease-out;
            width: 100%;
            height: 100%;
        }
    }
}

.toggle {
    $handle-size: 15px;
    $inner-padding: 1px;
    @include inputDefaults();
    margin-top: $spacing;
    margin-bottom: $spacing;
    input{
        display: none;
    }
    label {
        display: flex;
        align-items: center;
        &>* {
            margin: 0;
        }
    }
    .display {
        box-sizing: content-box;
        width: 2*$handle-size;
        height: $handle-size;
        background-color: $grey;
        border-radius: $handle-size;
        padding: $inner-padding;
        margin-right: $spacing/2;
        position: relative;
        transition: background-color $transition-duration ease-in-out;
        &:before {
            content: '';
            position: absolute;
            left: $inner-padding;
            display: block;
            height: $handle-size;
            width: $handle-size;
            background: #FFF;
            border-radius: 50%;
            transition: left $transition-duration ease-in-out;
        }
    }
    &:not(.disabled) .display {
        cursor: pointer;
    }
    input:checked + .display {
        background-color: $main-color;
        &:before {
            left: calc(100% - #{$handle-size + $inner-padding});
        }
    }
}

.datepicker {
    @include inputDefaults();
    input {
        font-size: 1rem;
        border: 1px solid $grey;
        border-radius: 3px;
        background: none;
        outline: none;
        padding: $spacing/2 $spacing;
    }
}

.textFieldsArray {
    .textFieldWithDelete {
        display: inline-block;
        margin-right: $spacing;
        & > * {
            vertical-align: middle;
        }
        .textInput {
            margin-right: 2px;
        }
        .deleteBtn {
            padding: $spacing/2;
            color: $strawberry;
            outline: none;
        }
    }
}

.radioTabs {
    display: flex;
    align-items: center;
    width: 100%;
    border-bottom: 1px solid $light-grey;
    background: $ultralight-grey;
    
    .option {
        margin-bottom: -1px;
        flex: 1;
        height: 100%;
        padding: $spacing/2;
        border-bottom: 2px solid transparent;
        text-align: center;
        opacity: 0.7;
        text-transform: uppercase;
        font-size: .75em;
        cursor: pointer;
        transition: all $transition-duration ease-in-out;

        &:hover {
            opacity: 1;
        }
        &.selected {
            opacity: 1;
            border-bottom-color: $main-color;
        }
    }
}

.radioBtns {

    .option {
        @include inputDefaults();
        display: inline-flex;
        vertical-align: middle;
        align-items: center;
        cursor: pointer;

        .dot {
            display: inline-block;
            position: relative;
            width: 1rem;
            height: 1rem;
            background-color: $light-grey;
            border: 1px solid $grey;
            border-radius: 50%;
            margin-right: .2rem;
            vertical-align: middle;

            &:before {
                content: "";
                display: block;
                border-radius: 50%;
                background: $main-color;
                width: 0;
                height: 0;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                transition: all $transition-duration ease-out;
            }
        }
        &.selected {
            .dot:before {
                $size: calc(100% - 2px);
                width: $size;
                height: $size;
                transition-timing-function: ease-in;
            }
        }
    }
}