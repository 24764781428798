@import '../../styles/constants.scss';

.header {
    display: flex;
    .title {
        font-size: 2rem;
        text-align: left;
        font-weight: 500;
    }
    
    .grow {
        flex: 1;
    }
}

.deleteRow {
    color: $strawberry;
}