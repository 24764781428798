@import '../../styles/constants.scss';

.contrastTitle {
    color: $grey;
    background: $ultralight-grey;
    padding: $spacing 2*$spacing;
    text-transform: uppercase;
    font-size: .7rem;
    letter-spacing: .025rem;
    font-weight: bold;
}