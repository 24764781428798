@import '../../styles/constants.scss';

.loadingSpinner {
    margin: $spacing auto 0;
}

.btn {
    text-transform: none;
    outline: none;
}

.root {
    height: 100%;
    display: flex;
    flex-direction: column;
    & > header {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        padding: $spacing 2*$spacing;
        border-bottom: 1px solid $light-grey;

        .btn {
            margin: 0 $spacing;
        }

        .left span {
            font-size: 1rem;
            font-weight: bold;
            vertical-align: middle;
        }
    }

    & > main {
        flex: 1;
        display: flex;
        overflow: hidden;
        .complaintGeneral {
            flex: 0 0 auto;
            border-right: 1px solid $light-grey;
            display: flex;
            flex-direction: column;
            padding-top: $spacing;

            .addlQuestions {
                margin-bottom: $spacing;
            }
        }
        .editSlideAndPreview {
            flex: 1;
            overflow: hidden;
            .slideEditor, .slidePreview {
                width: 50%;
                float: left;
                height: 100%;
                overflow: hidden auto;
                padding: $spacing;
            }
            .slideEditor {
                position: relative;
                z-index: 2;
                flex: 1;
                background: #FFF;
                $spread: 3px;
                box-shadow: 0px 0 6px rgba(0,0,0,.3);
            }
            .slidePreview {
                flex: 1;
                padding: 2*$spacing 4*$spacing $spacing;
                display: flex;
                flex-direction: column;
                .paper {
                    flex: 1;
                    background: #FFF;
                    padding: $spacing 2*$spacing;
                    box-shadow: 2px 2px 10px rgba(0,0,0,.3);
                    display: flex;
                    flex-direction: column;
                }
                .buttonRow {
                    flex: 0 0 auto;
                    text-align: center;
                    padding-top: $spacing;
                    .deleteSlideBtn {
                        @extend .btn;
                        color: $strawberry;
                    }
                }
            }
        }
    }
}
