@import '../../../../styles/constants.scss';

.buttons {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.btn {
    margin: $spacing;
    .icon {
        opacity: 0;
        width: 1rem;
        margin-right: $spacing;
        &.visible {
            opacity: 1;
        }
    }
}

.submitRow {
    text-align: center;
}