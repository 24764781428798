@import '../../../styles/constants.scss';

.root {
    height: 100%;
    display: flex;
    flex-direction: column;
    main {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
}

.slideTitle {
    font-size: 1rem;
    border: 1px solid $grey;
    border-radius: 3px;
    padding: $spacing/2 $spacing;
    background: none;
    word-break: break-word;
}