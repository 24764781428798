@import "../../styles/constants.scss";

.root {
    border-right: 1px solid #e3e5e6;
    padding: 2 * $spacing;
    display: flex;
    flex-direction: column;

    .title {
        text-transform: uppercase;
        color: grey;
        font-size: .8rem;
        font-weight: bold;
        letter-spacing: .125rem;
        text-align: left;
        margin: 2*$spacing 0;
    }

    .navItem {
        display: block;
        text-decoration: none;
        color: inherit;
        margin: 2*$spacing 0;
        &.current {
            color: $main-color;
        }
        .icon {
            display: inline-block;
            margin-right: $spacing;
            vertical-align: top;
        }
    }

    .logout {
        margin-top: auto;
    }
}