@import '../../../styles/constants.scss';

.slidesList {
    flex: 1;
    overflow: auto;
    .slideItem {
        display: flex;
        flex-wrap: nowrap;
        color: #000;
        &.selected {
            color: $main-color;
            background: #FFF;
        }
        &.dirty, &.invalid {
            .btn:after {
                content: '';
                display: block;
                height: .5rem;
                width: .5rem;
                border-radius: 50%;
            }
        }
        &.invalid .btn:after {
            background: $strawberry
        }

        .sortContainer {
            margin-left: $spacing;
            color: $grey;
            display: inline-flex;
            flex-direction: column;
            justify-content: center;
            .icon {
                height: 1rem;
                width: 1rem;
                cursor: pointer;
                transition: color 150ms;
                &:hover {
                    color: $main-color;
                }
                &.disabled {
                    opacity: 0;
                    cursor: initial;
                }
            }
        }
    }
    .btn {
        text-transform: none;
        outline: none;
        text-align: left;
        justify-content: flex-start;
        color: inherit;
        font-weight: bold;
    }
}